import React from "react"
import theme from "theme"
import { Theme, Text, Hr, Box, Section, Image, Strong } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>Про нас | Target Triumph!</title>
        <meta name={"description"} content={"Кожен кадр розповідає історію"} />
        <meta property={"og:title"} content={"Про нас | Target Triumph!"} />
        <meta
          property={"og:description"}
          content={"Кожен кадр розповідає історію"}
        />
        <meta
          property={"og:image"}
          content={"https://vervesco.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://vervesco.com/img/2564552.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://vervesco.com/img/2564552.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://vervesco.com/img/2564552.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://vervesco.com/img/2564552.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://vervesco.com/img/2564552.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://vervesco.com/img/2564552.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="url(https://vervesco.com/img/4.jpg) center/cover"
        padding="140px 0"
        sm-padding="40px 0"
        quarkly-title="About-16"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          justify-content="center"
          sm-min-width="280px"
        />
        <Box
          padding="50px 80px 50px 50px"
          color="--dark"
          display="flex"
          flex-direction="column"
          align-items="center"
          max-width="670px"
          background="#ffffff"
          sm-padding="50px 24px 50px 24px"
        >
          <Text margin="0 0 16px 0" as="h2" font="--headline2">
            Про Нас
          </Text>
          <Hr
            min-height="10px"
            margin="0px 0px 0px 0px"
            border-color="--color-dark"
            border-width="3px 0 0 0"
            max-width="50px"
            width="100%"
          />
          <Text font="--base" text-align="center">
            Target Triumph почався з простого бачення: створити першокласний
            тир, де поєднуються безпека, технології та пристрасть до точної
            стрільби. Наш шлях розпочався зі скромних починань, розвиваючись з
            кожним пострілом, оскільки ми прагнули забезпечити неперевершений
            досвід для любителів стрільби. Сьогодні Target Triumph є маяком для
            тих, хто цінує точність, пропонуючи поєднання традицій та інновацій
            під одним дахом.
          </Text>
        </Box>
      </Section>
      <Section
        padding="100px 0 100px 0"
        background="--color-light"
        quarkly-title="FAQ-8"
      >
        <Text
          margin="0px 0px 15px 0px"
          font="normal 600 42px/1.2 --fontFamily-sans"
          color="--darkL1"
        >
          Наші можливості
        </Text>
        <Text
          margin="0px 0px 70px 0px"
          font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
          color="#555a5f"
          sm-margin="0px 0px 50px 0px"
        >
          Наш тир оснащений найсучаснішим обладнанням та різноманітними
          стрілецькими доріжками, призначеними для різних стрілецьких дисциплін
          та рівнів майстерності. Від мішеней ручної роботи до автоматизованих
          систем пошуку мішеней - кожен елемент в Target Triumph розроблений для
          забезпечення точності. Наші просторі та добре освітлені приміщення
          гарантують, що кожен учасник, незалежно від його досвіду, знайде
          завдання, яке підійде саме йому.
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="50px 50px"
          lg-grid-template-columns="repeat(2, 1fr)"
          md-grid-template-columns="1fr"
          sm-grid-gap="35px 0"
        >
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 500 25px/1.2 --fontFamily-sans"
              color="--darkL1"
            >
              {" "}
			  Інноваційні технології: 
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
              color="#555a5f"
            >
              Ми використовуємо новітні технології, щоб забезпечити безперебійну зйомку.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 500 25px/1.2 --fontFamily-sans"
              color="--darkL1"
            >
              Виділені доріжки: 
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
              color="#555a5f"
            >
              Спеціалізовані доріжки для різних рівнів майстерності та дисциплін.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 500 25px/1.2 --fontFamily-sans"
              color="--darkL1"
            >
             Сталі практики: 
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
              color="#555a5f"
            >
              Ми прагнемо до сталого розвитку, застосовуючи екологічно чисті методи у своїй діяльності.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://vervesco.com/img/5.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Чому варто обрати Target Triumph?
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Приєднуйтесь до нас у Target Triumph і прокладіть свій шлях у світі високоточної стрільби. Тут кожен ваш постріл - це не просто влучання в ціль, а вдосконалення ваших навичок, зосередження уваги та вихід за межі ваших можливостей. Це не просто стрільба, це стиль життя, заснований на дисципліні та досконалості. Залиште свій слід у світі стрілецького спорту, обравши Target Triumph.

            <br />
            <br />
            <br />
            <br />
            <Strong>
			Відчуйте точність, досконалість та пристрасть під час кожного візиту!
            </Strong>
          </Text>
        </Box>
      </Section>
      <Components.New />
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
